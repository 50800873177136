.SmallBeadContainer {
    width: 50%;
    height: 100%;
    left: 25px;
    border-left: 1px solid black;
}

.tdMinWidth {
    min-width: 50px;
}

.textareaStyling {
    width: 100%;
    height: 90%;
    resize: vertical;
}