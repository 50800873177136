.subjectTabsContainer {
    width: fit-content;
    position: sticky;
    top: 16px;
    left: 66px;
    z-index: 3;
    background-color: #ffffff;
    width: 94vw;
    padding-left: 10px;
    padding-top: 10px;
    display:flex;
    justify-content:space-between;
}

.pageTitle {
    font-size: x-large;
    font-weight: bold;
    margin-right: 20px;
}