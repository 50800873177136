/* src/components/Sidebar.css */
body {
  font-family: "Lato", sans-serif;
}

.sidebar {
  height: 100%;
  width: 50px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 0px;
  font-size: 36px;
  margin-left: 50px;
  cursor: pointer;
}

#linkList {
  transition: opacity 0.5s;
  opacity: 0;
}

#linkList.show {
  opacity: 1;
}

#linkList.hide {
  opacity: 0;
}

.sidebar.open {
  width: 250px;
}

.sidebar.closed {
  width: 50px;
}

#main.open {
  margin-left: 250px;
}

#main.closed {
  margin-left: 50px;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
  margin-left: 50px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }
  .sidebar a {
    font-size: 18px;
  }
}

.nav-toggle {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 22px;
  width: 22px;
  cursor: pointer;
  z-index: 2;
}

.nav-toggle .bar {
  height: 3px;
  width: 100%;
  background-color: gray;
  transition: all 0.3s ease-in-out;
}

.nav-toggle.open .bar:nth-child(1) {
  transform: rotate(45deg);
  transform-origin: top left;
  width: 28px;
}

.nav-toggle.open .bar:nth-child(2) {
  opacity: 0;
}

.nav-toggle.open .bar:nth-child(3) {
  transform: rotate(-45deg);
  transform-origin: bottom left;
  width: 28px;
}

.layout {
  display: flex;
}
