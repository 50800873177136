.table-container {
  width: 100%; /* Optional: Makes sure the container fits its parent width */
}

.Bead {
  width: 20px;
  height: 20px;
  border-radius: 11px;
  transform: translate(-11.5px, 10px);
  border: solid 1px gray;
}

.UnplannedBead {
  width: 10px;
  height: 10px;
  border-radius: 6px;
  transform: translate(-6.5px, 15px);
  border: solid 1px gray;
  background-color: black;
}

.BeadContainer {
  width: 50%;
  height: 100%;
  left: 40px;
  border-left: 1px solid black;
  position: relative;
}

.BeadContainer:has(.Bead) {
  border-left: 1px solid black;
  position: relative;
}

.BeadContainer:has(.UnplannedBead) {
  border-left: 1px solid black;
  position: relative;
}

td {
  height: 40px;
}

.NoteCell {
  min-width: 150px;
  border-bottom: solid gray 1px;
}
