.login-container {
    display: flex;
    height: 100vh;
    width: 100%;
  }
  
.login-form-container {
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    display: flex;
  }

  .login-image {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
  
  .login-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .login-form {
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 200px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  button {
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    background-color: blue;
    color: white;
    cursor: pointer;
  }
  