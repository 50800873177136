.workName {
    max-width: 200px;
    min-width: 200px;
    position: sticky;
    left: 66px;
    background-color: white;
    z-index: 2;
    padding-left: 10px;
    padding-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.indentedWorkName {
    left: 25px;
}

.graySubtitle {
    color: gray;
    font-size: small;
    position: sticky;
    top: 0px;
    background-color: #ffffff;
}

.clickableTh {
    cursor:pointer;
}

.studentTh {
    min-width: 80px;
    position: sticky;
    top: 66px;
    background-color: #ffffff;
    z-index: 3;
    padding-top: 10px;
    cursor:pointer;
}

.mainWorkName {
    z-index: 5;
}