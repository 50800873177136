.subject-tab {
    padding: 10px 10px;
    cursor: pointer;
    border: none;
    margin-right: 5px; 
    white-space: nowrap;
    color: #9a9a9a;
    background-color: white;
    font-size: 16px;
}

.subject-tab:not(.subject-tab-active):hover {
    color: #6a6a6a;
}

.subject-tab-active {
    color: #5a5a5a; 
    font-weight: 600;
}